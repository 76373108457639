// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-movies-js": () => import("./../../../src/pages/movies.js" /* webpackChunkName: "component---src-pages-movies-js" */),
  "component---src-pages-sitelist-index-js": () => import("./../../../src/pages/sitelist/index.js" /* webpackChunkName: "component---src-pages-sitelist-index-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-work-journal-js": () => import("./../../../src/pages/work-journal.js" /* webpackChunkName: "component---src-pages-work-journal-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

