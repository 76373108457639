import React, { useMemo }  from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'

const AlignedImage = ({ image, alignment, altText, ...props }) => {

   const data = useStaticQuery(graphql`
    query {
      allFile( filter: { internal: { mediaType: { regex: "images/" } } } ) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let alignmentClass = 'align-'+alignment;

  const match = useMemo(() => (
    data.allFile.edges.find(({ node }) => image === node.relativePath)
  ), [ data, image ])


  return (
    <Img fluid={match.node.childImageSharp.fluid} className={alignmentClass} alt={altText} />
  )
}

export default AlignedImage
 